import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import FeatureList from "~components/sections/landingpages/FeatureList";
import KeySellingpoints from "~components/sections/landingpages/KeySellingpoints";
import LandingpageHeader from "~components/sections/landingpages/LandingpageHeader";
import SeoText from "~components/sections/landingpages/SeoText";
import Cta from "~components/sections/Cta";
import About from "~components/sections/landingpages/About";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";

export default function Page({ data, location }) {
	const keyword = "Webdesign Friseur";
	const title = "Das richtige Webdesign für jeden Friseur";
	const description =
		"So gepflegt die Haare Deiner Kunden sind, so gepflegt sollte auch das Webdesign bei jedem Friseur sein.";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.headerImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.headerImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.headerImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<SchemaProduct
				name={keyword}
				image={data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}
				description={description}
				aggregateOffer
				lowPrice="500"
				highPrice="5000"
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
			/>
			<LandingpageHeader
				headline={{ one: title }}
				image={{ data: data.headerImage.header, alt: `${keyword} - Friseure im Portrait` }}
				text={
					<>
						Du als <strong>Friseur</strong> weißt: Der erste Eindruck zählt! So gepflegt die Haare Deiner Kunden sind,
						so gepflegt sollte auch das <strong>Webdesign</strong> Deiner Website sein. Wir möchten Dir auf dieser Seite
						zeigen, was die Vorteile einer guten Website sind und wie Du Dich erfolgreich positionieren kannst.
					</>
				}
				buttonOne={{ text: "Kostenloses Beratungsgespräch vereinbaren", to: `${location.pathname}anfragen/` }}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
			/>

			<KeySellingpoints
				headline="Webdesign für Friseure. Oder: Wozu benötigst Du eine Website?"
				intro="Eine Webseite ist wichtig, dass ist klar. Aber hast Du Dir Gedanken gemacht, warum dass so ist? Die wichtigsten drei Punkte im Bereich Webdesign für Friseure sind wie folgt:"
				sellingponts={[
					{
						headline: "Informationszentrale",
						text: [
							"Die Website ist der Grundbaustein für die Außenkommunikation zu Deinen Kunden. Von Preisen, über Öffnungszeiten bis hin zu Betriebsferien. Viele Deiner (potenziellen) Kunden suchen nach diesen grundlegenden Informationen. Diese Informationen sollten schnell auf Deiner Website und in den sozialen Medien gefunden werden.",
						],
						image: data.imageIntro1,
						alt: `${keyword} - Schöner Salon`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Website erstellen",
						},
					},
					{
						headline: "Deine Wirkung nach Außen",
						text: [
							"Du berätst Deine Kunden zu Ihrem Aussehen und daher muss es umso wichtiger sein, dass Du auch professionell und durchdacht nach außen wirkst. Ein Bestandteil Deiner Außenkommunikation ist das Design Deiner Marke und das Webdesign. Sie sind der Ankerpunkt für die Kundenkommunikation und sollten genau wie bei der Frisur vom Profi gemacht worden sein.",
						],
						image: data.imageIntro2,
						alt: `${keyword} - Kunde bekommt die Haare gemacht`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Image verbessern",
						},
					},
					{
						headline: "Online Terminkalender",
						text: [
							"Neben der Außenkommunikation ist die Website auch die digitale Plattform und eine Chance für Dich bestimmte Dinge zu automatisieren. Wie weniger Du Dich mit der Organisation beschäftigen musst, umso mehr kannst Du Deiner Hauptarbeit nachgehen. Mit unseren intelligenten Systemen können wir Schnittstellen schaffen und z. B. eine automatische Terminvergabe in Deine Website einbinden. So sparst Du Zeit und der Kunde kann sich einfach einen passenden Termin bei Dir aussuchen.",
						],
						image: data.imageIntro3,
						alt: `${keyword} - Nahaufnahme eines Terminkalenders`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Zeit sparen",
						},
					},
				]}
			/>

			<FeatureList
				headline={`${keyword}: Unser Leistungsspektrum`}
				text={`Uns sind ganzheitliche Lösungen für unsere Kunden über aus wichtig. Daher bieten wir auch für den Bereich ${keyword} eine ganzheitliche Lösung für Deine Website. Ein einfacher Auftrag genügt und wir kümmern uns um die erfolgreiche Projektumsetzung und darüber hinaus.`}
				features={data.site.siteMetadata.globalFeautureList.webdesign}
			/>

			<SeoText
				headline="Welche online Marketing-Möglichkeiten hast Du als Friseur?"
				intro={[
					"Eine saubere Website und gutes SEO sind wichtig, dass Du im Netz gefunden wirst. Fast jeder Friseur ist technisch versiert eine gute Website zu programmieren und das ist auch gut so. Wir schneiden auch keine Haare. Aber was kannst Du als Friseur selbst machen?",
				]}
				text={[
					{
						headline: "Social Media",
						text: [
							"Platziere Dich mit regelmäßigen Postings aus Deinem Laden in den sozialen Netzwerken. Aber Achtung! Konzentriere Dich am besten erst mal nur auf eine Plattform wie Instagram und fülle dieses einmal richtig, bevor Du Dich in zu vielen Netzwerken anmeldest und keinen Deiner Accounts richtig pflegen kannst.",
						],
					},
					{
						headline: "Online-Bewertungen",
						text: [
							"Ein Eintrag bei Google Business ist für jedes Ladengeschäft Pflicht. Mit einem Eintrag trägst Du Dich automatisch bei Google Maps ein und kannst im Anschluss Bewertungen sammeln. Mit einer hohen Zahl an Bewertungen und einer guten Durchschnittsnote baust Du so Vertrauen zu potenziellen Kunden auf, die noch nie etwas von Dir gehört haben.",
						],
					},
					{
						headline: "Online-Blog",
						text: [
							"Wenn Du ein wenig technischer bist und schon einen Blog aufsetzten kannst, dann hilft auch ein Online-Blog. Beschreibe den täglichen Wahnsinn und Du wirst merken wie auch hier Deine Sichtbarkeit bei den Suchmaschinen steigt.",
						],
					},
				]}
			/>

			<Cta
				title={`${keyword} jetzt anfragen`}
				titleAs="p"
				text="Haben wir Dein Interesse geweckt? Dann fragen gerne ein unverbindliches Gespräch bei uns an und wir schauen, ob wir zueinanderpassen."
				button={`${keyword} anfragen`}
				to={`${location.pathname}anfragen/`}
			/>

			<SeoText
				text={[
					{
						headline: "Was muss eine gute Friseur-Website beinhalten?",
						headlineAs: "h2",
						text: [
							<>
								Gutes <strong>Webdesign für Friseure</strong> beginnt beim Auftreten und hört bei der Sichtbarkeit auf.
								Doch woran erkennst Du eine gute Website und was musst Du als Friseur beachten? Wir erachten folgende
								Dinge als besonders wertvoll für Friseure:
							</>,
							<ul className="mt-3 list-disc space-y-3 pl-5">
								<li>Ein Top-Auftreten und eine individuelle und beeindruckende Website</li>
								<li>
									Individuelle Bilder von Deinem Salon und eventuell von Dir und Deinem Team, um Kundennähe zu fördern
								</li>
								<li>Eine Übersicht über die angebotenen Leistungen</li>
								<li>Einfache Kontaktaufnahme oder noch besser: Ein automatisches Terminvergabetool</li>
								<li>Informationen zu Öffnungszeiten und Betriebsurlauben</li>
								<li>Die Anschrift und ggf. Parkmöglichkeiten und Verkehrsanbindungen</li>
								<li>Spezielle Angebote</li>
								<li>Mechanismen, sodass die Website auch gefunden wird</li>
							</ul>,
						],
					},
					{
						headline: "Benötigst Du eine professionelle Agentur?",
						headlineAs: "h2",
						text: [
							"Gutes Webdesign ist heute dank unzähliger Baukästen nicht mehr schwer. Drei Klicks, Bilder rein und einen guten beschreibenden Text und Deine Website ist online. Jemand, der technisch Affin ist, kann seinen Salon so schnell online präsentieren. Doch was ist das Ziel Deiner Website? Gutes Webdesign ist nicht die richtige Antwort. Viel wichtiger ist die Kundenkommunikation und Kundengenerierung. Und sowohl die Kommunikation und die Generierung kosten Zeit, Geld und benötigen eine Expertise, um sie souverän zu meistern. Strebst Du also eine einfache Webvisitenkarte an, die im Netz nicht sichtbar sein soll, dann bist Du im Alleingang ausreichend bedient. Sobald Du aber bereit bist einen Schritt weiterzugehen und Dich zu Digitalisieren, dann kommst Du an einer guten und professionellen Agentur nicht vorbei.",
							"Eine gute Agentur kümmert sich nicht nur um das Webdesign, sondern kümmert sich auch um die Ziele Deiner Website. Was möchtest Du damit erreichen? Kundengewinnung, Automatisierung, Skalierung? Das kann nur ein Dienstleister leisten, da Du selbst ab einer bestimmten Größe auf Dein Business konzentrieren musst.",
							"Du kannst gerne uns als Webdesign Agentur beauftragen und wir erstellen Dir eine Website. Kennst Du auch unser Website-Abo? Unser Abo-Modell versteht sich als Website as a Service. Das bedeutet es richtet sich an alle Unternehmer da draußen, die sich NICHT mit der Technik und dem Webdesign beschäftigen wollen und sich auf ihr eigenes Geschäfts konzentrieren wollen. Die attraktive Rate und der monatliche Service sorgen dafür, dass wir immer als Ansprechpartner zur Verfügung stehen und Technik und Design jederzeit anpassen können. So kannst Du Dich voll auf Deinen Salon konzentrieren und wir kümmern uns um alle Aspekte auf Deiner Website.",
						],
					},
					{
						headline: "Spezialist für lokale Sichtbarkeit",
						headlineAs: "h2",
						text: [
							"Du bist als Friseur neu auf dem Markt oder planst Dich selbstständig zu machen? Du hast schon Kunden, aber Du möchtest noch größer werden? Du hast schon einen erfolgreichen Salon und möchtest ein Franchise aufbauen?",
							"Wir bieten Lösungen für Salons an, um die Sichtbarkeit in Deiner Stadt zu erhöhen. Die Website ist die Eingangstür zu Deinem Unternehmen. Bevor der Kunde zu Dir kommt, besucht er Dich online. Damit aber Deine potenziellen Kunden Dich auch bei Google und Co. finden, musst Du gewisse Maßnahmen treffen. In diesem Fall reicht ein schönes Webdesign nicht.",
							<>
								Mit intelligentem Design und ausgefeilter Technologie erschaffen wir Websites, die nachhaltig Deine
								Prozesse optimieren und Kunden ohne teure Werbeanzeigen beschaffen. Mit der SEO Premium Agentur aus
								Stuttgart arbeiten wir mit einem Partner zusammen, der sich auf Local SEO konzentriert. Local SEO
								beschreibt dabei die Suchmaschinenoptimierung Deiner Website für Suchbegriffe, die besonders in Deiner
								Stadt gesucht werden. So ist auch diese Seite eine Maßnahme einer solchen Optimierung. Möchtest Du
								wissen wie viele Menschen monatlich nach einem Friseur in Deiner Stadt suchen? Mache{" "}
								<Link to="/leistungen/umsatz-steigern/" className="underline">
									hier den Test
								</Link>{" "}
								und rechne aus, wie viel Umsatz Du machen könntest, wenn Du bei den Suchmaschinen auf der ersten Seite
								wärst.
							</>,
						],
					},
				]}
			/>

			<About link={`${location.pathname}anfragen/`} keyword={keyword} />
		</Layout>
	);
}

export const query = graphql`
	{
		headerImage: file(relativePath: { eq: "pages/leistungen/webdesign-friseur/webdesign-friseur_header.jpg" }) {
			header: childImageSharp {
				gatsbyImageData
			}
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageIntro1: file(relativePath: { eq: "pages/leistungen/webdesign-friseur/webdesign-friseur_salon.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageIntro2: file(relativePath: { eq: "pages/leistungen/webdesign-friseur/webdesign-friseur_wirkung.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageIntro3: file(relativePath: { eq: "pages/leistungen/webdesign-friseur/webdesign-friseur_termin.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		site {
			siteMetadata {
				siteUrl
				globalFeautureList {
					webdesign {
						headline
						text
					}
				}
			}
		}
	}
`;
